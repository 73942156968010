import React, { JSXElementConstructor } from 'react';

import { ProGallery, GALLERY_CONSTS } from 'pro-gallery';
import {
  resizeMediaUrl,
  resizeMediaUrlForOldGoG,
} from '../../../../services/itemResizer';
import AlbumInfo from './AlbumInfo';
import {
  defaultPGStyleParams,
  gogSpecificGalleryStyleParams,
} from '../../../../services/styleParams/defaultStyleParams';
import { oldGogToGallery } from '../../../../services/styleParams/converter';

import _ from 'lodash';
import TPADimensionsHelper from '@wix/photography-client-lib/dist/src/utils/TPADimensionsHelper';
import { getProGalleryStyles } from '@wix/photography-client-lib/dist/src/utils/proGalleryStyleBuilder';
import DimensionsHelper from '../../../../services/dimensionsHelper';
import './wixStyles.scss';
import { getGalleryViewMode } from '../../../../services/galleryHelper';

export default class PgWrapper extends React.Component<
  PgWrapperProps,
  PgWrapperState
> {
  shouldResize: boolean;
  dimensionsHelper: any;
  galleryHeight: number | undefined;
  oneRowFromGalleryStructure: any;
  constructor(props: PgWrapperProps) {
    super(props);
    this.state = {
      container: {
        width: this.props.dimensions.width || (window && window.innerWidth),
        height: this.props.dimensions.height || (window && window.innerHeight),
        avoidGallerySelfMeasure: true,
      },
      lastViewMode: this.props.viewMode,
    };
    this.handleEvent = this.handleEvent.bind(this);
    this.shouldResize = !this.props.dimensions.width;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.setHeightImp = this.setHeightImp.bind(this);
    this.handleNewGalleryStructure = this.handleNewGalleryStructure.bind(this);
    this.dimensionsHelper = new DimensionsHelper(this, props);
    this.createContainer = this.createContainer.bind(this);
    this.getScrollingElement = this.getScrollingElement.bind(this);
  }

  setHeightImp = (newHeight?: number) => {
    // need to check every time since it can be changed when switching between editor/preview
    if (this.props.Wix && typeof this.props.Wix.setHeight === 'function') {
      this.props.Wix.setHeight(newHeight);
    } else {
      typeof this.props.setHeight === 'function' &&
        this.props.setHeight(newHeight);
    }
  };

  handleNewGalleryStructure(newGalleryStructureData: any) {
    const {
      numOfItems,
      container,
      layoutHeight,
      updatedHeight,
      styleParams,
      isInfinite,
    } = newGalleryStructureData;

    // add hack for changing from editor to preview
    this.oneRowFromGalleryStructure = styleParams;
    const galleryHeight = TPADimensionsHelper.setWixHeight({
      height: layoutHeight,
      offsetTop: 0,
      container,
      numOfItems,
      updatedHeight,
      isInfinite,
      setHeightImp: this.setHeightImp,
      viewMode: this.props.viewMode,
      styleParams,
      clearHeight: this.props.Wix && this.props.Wix.clearHeight,
    });

    try {
      this.props.updateLayout && this.props.updateLayout();
    } catch (e) {
      console.log('Cannot call updateLayout', e);
    }
  }

  getScrollingElement() {
    try {
      if (typeof window !== 'undefined' && window.top === window.self) {
        // OOI
        return window;
      } else {
        // iFrame
        // return a "mock" of the window
        return {
          addEventListener: (eventName: string, callback: any) => {
            this.props.Wix.addEventListener(eventName.toUpperCase(), callback);
          },
          removeEventListener: (eventName: string, callback: any) => {
            this.props.Wix.removeEventListener(
              eventName.toUpperCase(),
              callback,
            );
          },
        };
      }
    } catch (e) {
      console.error('Cannot get scrolling element', e);
      return {};
    }
  }

  createContainer() {
    this.props.Wix &&
      this.props.Wix.getBoundingRectAndOffsets((rect: any) => {
        this.setState({
          container: {
            scrollBase: rect.offsets.y * rect.scale || 0,
            width: TPADimensionsHelper.protectGalleryWidth(
              this.props.isMobile
                ? document.body.clientWidth
                : window.innerWidth,
            ),
            height: TPADimensionsHelper.protectGalleryHeight(
              window.innerHeight,
              0,
            ),
          },
        });
      });
  }

  handleEvent(eName: string, eData: any) {
    if (eName === GALLERY_CONSTS.events.ITEM_ACTION_TRIGGERED) {
      if (typeof this.props.onAlbumClick === 'function') {
        this.props.onAlbumClick(this.props.coverIdToAlbum[eData.id]);
      }
    }
    if (eName === GALLERY_CONSTS.events.GALLERY_CHANGE) {
      this.handleNewGalleryStructure(eData);
    }
  }

  componentDidMount() {
    if (this.props.viewMode === 'Editor') {
      // this.setHeightImp(this.state.container.height); // check if necessary
      this.createContainer();
    } else {
      this.dimensionsHelper.setParentDidMount();
      this.dimensionsHelper.createResizeObserver();
      this.dimensionsHelper.createIntersectionObserver();
      this.updateDimensions();
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.viewMode === 'Editor') {
      this.createContainer();
    } else {
      this.updateDimensions(nextProps);
    }
  }

  updateDimensions(newProps?: any) {
    this.dimensionsHelper.update(newProps || this.props);
  }

  render() {
    let container = this.state.container;

    const isProGalleryGoG =
      this.props.styleParams.booleans &&
      this.props.styleParams.booleans.isProGalleryGoG;
    let InfoElement: JSXElementConstructor<ItemProps>;
    let galleryStyleParams: any;
    const additionalPgProps: PGProps = {};
    let resizeMediaUrlFunc;
    if (isProGalleryGoG) {
      galleryStyleParams = {
        ...getProGalleryStyles(this.props.styleParams),
        ...gogSpecificGalleryStyleParams,
      };
      resizeMediaUrlFunc = resizeMediaUrl;
    } else {
      galleryStyleParams = oldGogToGallery(this.props.styleParams);
      InfoElement = (itemProps: ItemProps) => (
        <AlbumInfo
          key={itemProps.id}
          dimensions={{ width: itemProps.style.width }}
          data={this.props.coverIdToAlbum[itemProps.id]}
          styleParams={galleryStyleParams}
          textPresets={this.props.textPresets}
          t={this.props.t}
        />
      );

      resizeMediaUrlFunc = resizeMediaUrlForOldGoG;
      additionalPgProps.customInfoRenderer = (itemProps: ItemProps) => {
        return <InfoElement {...itemProps} />;
      };

      container = { width: this.state.container.width };
    }

    const viewMode = getGalleryViewMode(this.props.viewMode);

    return (
      <div
        id={'gallery-wrapper-' + this.props.compId}
        className={isProGalleryGoG ? 'pgGog' : ''}
      >
        <ProGallery
          domId={this.props.compId}
          allowSSR={true}
          items={this.props.galleryItems}
          resizeMediaUrl={resizeMediaUrlFunc}
          eventsListener={this.handleEvent}
          container={container}
          scrollingElement={this.getScrollingElement}
          viewMode={viewMode}
          options={{
            ...defaultPGStyleParams,
            ...galleryStyleParams,
          }}
          {...additionalPgProps}
        />
      </div>
    );
  }
}
